/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const { default: links } = require('./src/constants/links');

exports.onClientEntry = () => {
  // console.log(window);
};

exports.shouldUpdateScroll = (e) => {
  const { routerProps, prevRouterProps } = e;
  const previousPath = prevRouterProps?.location.pathname || '';
  const currentPath = routerProps.location.pathname;

  /**
   * Prevent scrolling between sub pages of open-source page
   *
   * If previous route and current route starts with open-source path
   * it means that the user navigated between open-source subpages
   */
  if (previousPath.startsWith(links.openSource.home) && currentPath.startsWith(links.openSource.home)) {
    return false;
  }

  window.scrollTo(0, 0);

  return false;
};
